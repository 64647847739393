import { ethers } from 'ethers';


export interface Contracts {
    redemption: string,
    sifu: {
        contract: string,
        decimal: number
    },
    usdt: {
        contract: string,
        decimal: number
    },
    uwu: {
        contract: string,
        decimal: number
    },
}


interface NetworkConfig {
    name: string;
    chainId: number;
    publicJsonRPCUrl: string[];
    privateJsonRPCUrl: string[];
}

const providers: { [network: string]: ethers.providers.Provider } = {};

export const networkConfig: NetworkConfig = {
    name: 'Mainet',
    chainId: 1,
    publicJsonRPCUrl: ['https://eth.llamarpc.com'],
    privateJsonRPCUrl: ['https://eth.llamarpc.com']
}

export const config: Contracts = {
    redemption: '0x58e5255890Cf1693459EEB2a41BbcC53BFAba537',
    sifu: {
        contract: '0x29127fE04ffa4c32AcAC0fFe17280ABD74eAC313',
        decimal: 18
    },
    usdt: {
        contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimal: 6
    },
    uwu: {
        contract: '0x55C08ca52497e2f1534B59E2917BF524D4765257',
        decimal: 18
    }
}

export const getProvider = (chainId: number): ethers.providers.Provider => {
  
    const chainProviders: ethers.providers.StaticJsonRpcProvider[] = [];

    if (networkConfig.privateJsonRPCUrl.length > 0) {
        providers[chainId] = new ethers.providers.StaticJsonRpcProvider(
            networkConfig.privateJsonRPCUrl[0],
            chainId,
        );
        return providers[chainId];
    }
    
    if (networkConfig.publicJsonRPCUrl.length > 0) {
        networkConfig.publicJsonRPCUrl.map((rpc) =>
            chainProviders.push(
            new ethers.providers.StaticJsonRpcProvider(rpc, chainId),
            ),
        );
    }

    if (!chainProviders.length) {
    throw new Error(`${chainId} has no jsonRPCUrl configured`);
    }
    
    if (chainProviders.length === 1) {
        providers[chainId] = chainProviders[0];
    } else {
        providers[chainId] = new ethers.providers.FallbackProvider(
            chainProviders,
        );
    }
    
    return providers[chainId];
};