import BigNumber from 'bignumber.js';
import { ethers, utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { config, getProvider, networkConfig } from '../../config';
import { 
    useAccount,
    useSigner,
    useConnect,
} from 'wagmi'
import { providers } from 'ethers';
import { Web3Button } from '@web3modal/react'
import sify from '../../images/icons/sifu.svg'
import usdt from '../../images/icons/usdt.svg'
import uwu from '../../images/icons/uwu.svg'
import { Redemption__factory, SIFUToken__factory } from '../../typechain-types';
import './styles.scss'
import { notification } from 'antd';
import { useWeb3React } from '@web3-react/core';
import type { NotificationPlacement } from 'antd/es/notification/interface';


export default function Home() {
    const [api, contextHolder] = notification.useNotification();
    const account = useAccount();
    const signer = useSigner();
    const connect = useConnect({
        onSuccess(data) {
            console.log('data', data)
        }
    });

    const web3 = useWeb3React<providers.Web3Provider>();
    const [isLoadingApprove, setIsLoadingApprove] = useState(false);
    const [isLoadingRedeem, setIsLoadingRedeem] = useState(false);

    const [balance, setBalance] = useState({
        sifu: '0',
    })

    const [allowance, setAllowance] = useState({
        sifu: 0,
    })

    const [exchangeRates, setExchangeRates] = useState({
        usdt: '0',
        uwu: '0'
    })


    const [exchangeSifuValues, setExchangeSifuValues] = useState('0')
    const [exchangeUsdtValues, setExchangeUsdtValues] = useState('0')
    const [exchangeUwuValues, setExchangeUwuValues] = useState('0')

    const [disabledButtons, setDisabledButtons] = useState({
        approve: true,
        redeem: true
    })

    const [error, setError] = useState('');

    useEffect(() => {
        console.log('signer', signer)
        if (signer.isIdle) {
            setError('Please connect wallet for redeem')
        } else {
            
                const allowanceFetch = async () => {

                    if (signer.data && account.address) {
                        const sifuToken = SIFUToken__factory.connect(
                            config.sifu.contract,
                            signer.data
                        )

                        const allowanceBalance = await sifuToken.allowance(
                            account.address,
                            config.redemption
                        )
                        const bgAllowanceBalance = new BigNumber(allowanceBalance.toString());
                        setAllowance({
                            sifu: bgAllowanceBalance.div(10 ** config.sifu.decimal).toNumber()
                        })
                    }
                }
                allowanceFetch()
                setError('')
        }
    }, [signer.data])

    useEffect(() => {

        const fetchData = async () => {
            const sifuToken = SIFUToken__factory.connect(
                config.sifu.contract,
                provider
            )
            const redeemContract = Redemption__factory.connect(
                config.redemption,
                provider
            )

            if (account.address) {
                const sifuBalance = await sifuToken.balanceOf(account.address);
                const bgSifuBalance = new BigNumber(sifuBalance.toString())

                setBalance({
                    ...balance,
                    sifu: bgSifuBalance.div(10 ** config.sifu.decimal).toFixed(2).toString()
                })
            }
    
            const usdtExchangeRate = await redeemContract.exchangeRates(config.usdt.contract)
            const uwuExchangeRate = await redeemContract.exchangeRates(config.uwu.contract)

            const rateOfUSDT = new BigNumber(usdtExchangeRate.toString())
            const rateOfUwu = new BigNumber(uwuExchangeRate.toString())

            setExchangeRates({
                usdt: rateOfUSDT.div(10 ** config.usdt.decimal).toString(),
                uwu: rateOfUwu.div(10 ** config.uwu.decimal).toString()
            })
        }
        
        fetchData()
    }, [])

    useEffect(() => {
        const redemption = Redemption__factory.connect(
            config.redemption,
            provider
        )
        const value = new BigNumber(exchangeSifuValues.toString());

        if (exchangeSifuValues === '') {
            setExchangeUsdtValues('0')
            setExchangeUwuValues('0')

        } else if (!value.isZero()) {
            redemption.calculateRedeem(
                value.multipliedBy(10 ** config.sifu.decimal).toString()
            ).then(data => {

                for (let prop in config) {
                    if (typeof (config as any)[prop].contract !== undefined) {
                        
                        const index = data[0].findIndex((item: string) => item === (config as any)[prop].contract)

                        if (index !== -1) {
                            const decimal = (config as any)[prop].decimal;
                            const exchangeValue = new BigNumber(data[1][index].toString())

                            if (prop === 'uwu') {
                                setExchangeUwuValues(
                                    exchangeValue.div(10 ** decimal).toString()
                                )
                            }

                            if (prop === 'usdt') {
                                setExchangeUsdtValues(
                                    exchangeValue.div(10 ** decimal).toString()
                                )
                            }
                        }
                    }
                }
            })
        } 
    }, [exchangeSifuValues])

    const handleChange = (e: any, symbol: string) => {

        switch(symbol) {
            case 'sifu':
                    setExchangeSifuValues(e.currentTarget.value)
                break;
            case 'usdt':
                    setExchangeUsdtValues(e.currentTarget.value)
                break;
            case 'uwu':
                    setExchangeUwuValues(e.currentTarget.value)
                break;        
        }

        const _disabledButtons = {
            ...disabledButtons
        }

        if (signer.isSuccess && Number(e.currentTarget.value) !== 0 && allowance.sifu <= Number(e.currentTarget.value)) {
            _disabledButtons.approve = false
        } else {
            _disabledButtons.approve = true
        }

        if (signer.isSuccess && _disabledButtons.approve && Number(e.currentTarget.value) !== 0) {
            _disabledButtons.redeem = false
        } else {
            _disabledButtons.redeem = true
        }

        setDisabledButtons(_disabledButtons)


    }
    
    const handleApprove = async () => {

        if (signer.data?.provider && !disabledButtons.approve) {
            console.log(
                'signer.data', signer.data.provider
            )

            const sifuToken = SIFUToken__factory.connect(
                config.sifu.contract,
                signer.data
            )

            if (account.address) {
                setIsLoadingApprove(true)
                setDisabledButtons({
                    ...disabledButtons,
                    approve: true
                })
                const sifuAmount = new BigNumber(exchangeSifuValues.toString())

                const estimateLimit = await sifuToken.estimateGas.approve(
                    config.redemption,
                    ethers.BigNumber.from(sifuAmount.multipliedBy(10 ** config.sifu.decimal).toString())
                )

                const gasLimit = estimateLimit.gt(45000) ? estimateLimit : 90000
                
                sifuToken.approve(
                    config.redemption, 
                    ethers.BigNumber.from(sifuAmount.multipliedBy(10 ** config.sifu.decimal).toString()),
                    {gasLimit: gasLimit}
                ).then(tx => {
                    tx.wait(1).then(txReceipt => {
                        openNotification('topRight', 'Transaction successful')
                        setIsLoadingApprove(false)
                        setDisabledButtons({
                            ...disabledButtons,
                            approve: false
                        })
                    }).catch((err) => {
                        console.log('err', err)
                        openError('topRight', 'Transaction error')
                        setIsLoadingApprove(false)
                        setDisabledButtons({
                            ...disabledButtons,
                            approve: false
                        })
                    })
                }).catch((err) => {

                    setIsLoadingApprove(false)
                    openError('topRight', err.reason)
                    setDisabledButtons({
                        ...disabledButtons,
                        approve: false
                    })
                })
            }
        }
    }

    const handleRedeem = async () => {
        if (signer.data?.provider && !disabledButtons.redeem) {
            const redemption = Redemption__factory.connect(
                config.redemption,
                signer.data
            )

            if (account.address) {
                setIsLoadingRedeem(true)
                setDisabledButtons({
                    ...disabledButtons,
                    redeem: true
                })
                const amount = new BigNumber(exchangeSifuValues.toString())

                redemption.redeem(
                    ethers.BigNumber.from(amount.multipliedBy(10 ** config.sifu.decimal).toString())
                ).then(tx => {
                    tx.wait(1).then(txReceipt => {
                        openNotification('topRight', 'Transaction successful')
                        setIsLoadingRedeem(false)
                        setDisabledButtons({
                            ...disabledButtons,
                            redeem: false
                        })
                    }).catch((err) => {
                        console.log('err', err)
                        openError('topRight', 'Transaction error')
                        setIsLoadingRedeem(false)
                        setDisabledButtons({
                            ...disabledButtons,
                            redeem: false
                        })
                    })
                }).catch((err) => {

                    setIsLoadingRedeem(false)
                    openError('topRight', err.reason)
                    setDisabledButtons({
                        ...disabledButtons,
                        redeem: false
                    })
                })
                setIsLoadingRedeem(false)
            }
        }
    }

    const openError = (placement: NotificationPlacement, description: string) => {
        api.error({
            message: `Error`,
            description: description,
            placement,
        });
    }

    const openNotification = (placement: NotificationPlacement, description: string) => {
        api.info({
            message: `Notification`,
            description: description,
            placement,
        });
    }

    const provider: ethers.providers.Provider = getProvider(networkConfig.chainId);
    
    return (
        <div className="Home">
            <div className="Home__left"></div>
            <div className="Home__center">
                {contextHolder}
                <div className="Home__header"><Web3Button  /></div>
                <div className="Home__header">Redeem your SV tokens for liquid assets below</div>
                <div className="Home__body">
                    <div className="Home__body-container">
                        <div className="Home__row Home__row-first">
                            <div className="Home__row-first__data">
                                <div className="Home__row__value">
                                    <div className="Home__row__volume">
                                        <input className="Home__row__volume__input" 
                                            type="number"
                                            value={exchangeSifuValues} 
                                            onChange={(e) => handleChange(e, 'sifu')}/>
                                    </div>
                                    {/* <div className="Home__row__price">$0</div> */}
                                </div>
                                <div>
                                    <div className="Home__row__symbol">
                                        <div className="Home__row__symbol__icon">
                                            <img src={sify} alt="" className="sifuIcon" />
                                        </div>
                                        <div className="Home__row__symbol__name">SIFU</div>
                                    </div>
                                    <div className="Home__row__balance">Balance: {balance.sifu}</div>
                                </div>
                            </div>
                            <div className="Home__row-first__arrow">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="2" width="36" height="36" rx="10" fill="#292330" stroke="black" strokeWidth="4"/>
                                    <path d="M20 27.575C19.8667 27.575 19.7417 27.5543 19.625 27.513C19.5083 27.4717 19.4 27.4007 19.3 27.3L12.7 20.7C12.5 20.5 12.4 20.2627 12.4 19.988C12.4 19.7133 12.5 19.4757 12.7 19.275C12.9 19.075 13.1333 18.975 13.4 18.975C13.6667 18.975 13.9 19.075 14.1 19.275L19 24.175V12.975C19 12.6917 19.096 12.4583 19.288 12.275C19.48 12.0917 19.7173 12 20 12C20.2833 12 20.521 12.096 20.713 12.288C20.905 12.48 21.0007 12.7173 21 13V24.175L25.9 19.275C26.1 19.075 26.3333 18.975 26.6 18.975C26.8667 18.975 27.1 19.075 27.3 19.275C27.5 19.475 27.6 19.7127 27.6 19.988C27.6 20.2633 27.5 20.5007 27.3 20.7L20.7 27.3C20.6 27.4 20.4917 27.471 20.375 27.513C20.2583 27.555 20.1333 27.5757 20 27.575Z" fill="white"/>
                                </svg>
                            </div>
                        </div>

                        <div className="Home__row">
                            <div className="Home__row__value">
                                <div className="Home__row__volume">
                                    <input className="Home__row__volume__input" 
                                            type="number" 
                                            value={exchangeUsdtValues} 
                                            onChange={(e) => handleChange(e, 'usdt')}
                                            disabled={true}
                                        />
                                </div>
                                {/* <div className="Home__row__price">$0</div> */}
                            </div>
                            <div className="Home__row__symbol">
                                <div className="Home__row__symbol__icon">
                                <img src={usdt} alt="" className="usdtIcon" />
                                </div>
                                <div className="Home__row__symbol__name">USDT</div>
                            </div>
                        </div>
                        <div className="Home__row">
                            <div className="Home__row__value">
                                <div className="Home__row__volume">
                                    <input className="Home__row__volume__input" 
                                        type="number" 
                                        value={exchangeUwuValues} 
                                        onChange={(e) => handleChange(e, 'uwu')}
                                        disabled={true}
                                    />
                                </div>
                                {/* <div className="Home__row__price">$0</div> */}
                            </div>
                            <div className="Home__row__symbol">
                                <div className="Home__row__symbol__icon">
                                    <img src={uwu} alt="" className="uwuIcon" />
                                </div>
                                <div className="Home__row__symbol__name">UWU</div>
                            </div>
                        </div>
                        <div className="Home__row Home__row-last">
                            <span>1 SV = {exchangeRates.usdt} USDT and {exchangeRates.uwu} UwU</span>
                        </div>
                        <div className="Home__buttons">
                            <div className={`Home__button ${ disabledButtons.approve ? 'disabled' : ''}`} onClick={handleApprove}>
                                {isLoadingApprove && <i className="fa fa-spinner fa-spin"></i>}
                                Approve
                            </div>
                            <div  className={`Home__button ${disabledButtons.redeem ? 'disabled' : ''}`} onClick={handleRedeem}>
                                {isLoadingRedeem && <i className="fa fa-spinner fa-spin"></i>}
                                Redeem
                            </div>
                        </div>
                        <div className={`Home__error ${error !== '' ? 'show' : ''}`}>{error}</div>
                    </div>
                </div>
            </div>
            <div className="Home__right">
                <div className="LogoContainer"></div>
                <div className="Logo"></div>
            </div>
        </div>
    )
}