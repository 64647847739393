import React from 'react';

import Home from './modules/Home';
import './App.scss';
import { Routes, Route } from "react-router-dom"

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'

const chains = [mainnet]
const projectId = '558f073b00094fd95ce81ae0c5a07c78'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

function App() {
  
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WagmiConfig client={wagmiClient}>
          <div className="App">
            <Routes>
              <Route path='/' element={<Home />} />
            </Routes>
                      {/* <div className="LogoContainer"></div>
              <div className="Logo"></div> */}
          </div>
        </WagmiConfig>
      </Web3ReactProvider>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
